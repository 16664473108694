import { FC, useState } from 'react';
import {
    AdminLayout,
    AdminLayoutContentCard,
    BadgePill,
    DeleteConfirmationModal,
    Pagination,
    SearchBarInput,
    Table,
} from '../../../common/components';
import { useBatteries } from '../../../common/hooks';

const BatteryStatus: FC<any> = ({ item }) => {
    if (item.is_faulty) {
        return (
            <div>
                <BadgePill color="red">Defeito</BadgePill>
            </div>
        );
    }

    return <div>-</div>;
};

const headers = [
    { label: 'Número de série', path: 'serial_number' },
    { label: 'Parceiro', path: 'org.name' },
    // { label: 'Logs', path: 'to be implemented' },
    { label: 'Status', path: 'is_faulty', Component: BatteryStatus },
];

const BatteryList = () => {
    const { batteries, fetchBatteries, deleteBattery, loading } = useBatteries({
        fetchData: true,
    });
    const [search, setSearch] = useState('');

    const submitSearch = () => {
        fetchBatteries({ search });
    };

    const changePage = (page: number | string) => {
        if (page !== batteries?.meta.currentPage) {
            fetchBatteries({ search, page });
        }
    };

    const [batteryToDelete, setBatteryToDelete] = useState<User>();
    const closeBatteryDeleteModal = () => setBatteryToDelete(undefined);

    const onClickDelete = (user: User) => {
        setBatteryToDelete(user);
    };

    const onConfirmDelete = async () => {
        if (!batteryToDelete) return;
        try {
            await deleteBattery(batteryToDelete.id);
            setBatteryToDelete(undefined);
            submitSearch();
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout
            title="Baterias"
            titleButtonLabel="Adicionar bateria"
            titleButtonHref="/batteries/new"
        >
            <AdminLayoutContentCard>
                <SearchBarInput search={search} setSearch={setSearch} submit={submitSearch} />
                <Table
                    headers={headers}
                    data={batteries?.data || []}
                    className="mt-4"
                    editPath="/batteries/edit"
                    onClickDelete={onClickDelete}
                    loading={loading}
                    logKey="battery_id"
                    logTitle={(item) => `da bateria ${item.serial_number}`}
                />
                <Pagination meta={batteries?.meta} changePage={changePage} />
            </AdminLayoutContentCard>
            <DeleteConfirmationModal
                title="Excluir máquina"
                description="Você tem certeza que deseja excluir essa máquina? 
        Essa operação não pode ser desfeita."
                open={!!batteryToDelete}
                closeModal={closeBatteryDeleteModal}
                acceptButtonAction={onConfirmDelete}
                // acceptButtonLabel="conta"
            />
        </AdminLayout>
    );
};

export default BatteryList;
