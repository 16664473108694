import { Dialog, Transition } from '@headlessui/react';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FC, Fragment, useMemo } from 'react';
import {
    BadgePill,
    BasicButton,
    DeviceStatusPillComponent,
    Toggle,
} from '../../../common/components';
import { useDevices } from '../../../common/hooks';

interface Props {
    open: boolean;
    closeModal: () => void;
    device?: Device;
    refreshDeviceList: (a?: Device) => void;
}

const DeviceBatteriesModal: FC<Props> = ({ open, closeModal, device, refreshDeviceList }) => {
    const { updateDevice, updateBattery, ejectBattery, ejectAllBatteries, restartDevice } =
        useDevices();

    const _updateDevice = async (id: string = '', payload: any) => {
        if (!id) return;
        const _device = await updateDevice(id, payload);
        if (_device) {
            refreshDeviceList(_device);
        }
    };

    const updateDeviceFrozen = (val: boolean) => {
        _updateDevice(device?.id, { is_frozen: val });
    };

    const updateDeviceFaulty = (val: boolean) => {
        _updateDevice(device?.id, { is_faulty: val });
    };

    const _ejectBattery = (index: number, battery: Battery) => {
        if (!device) return;
        ejectBattery({
            device_id: device.id,
            lock_index: index,
            battery_serial_number: battery.serial_number,
        });
    };

    const toggleFaultyBattery = (index: number, battery: Battery) => {
        if (!device) return;

        const new_faulty_state = !battery.is_faulty;

        updateBattery(battery.id, { is_faulty: new_faulty_state });

        const _device = { ...device };
        if (_device.batteries) {
            _device.batteries[index] = { ...battery, is_faulty: new_faulty_state };
        }

        refreshDeviceList(_device);
    };

    const batteries = useMemo(() => {
        const batteries = device?.batteries
            ?.map((battery) => ({ [battery.device_slot]: battery }))
            .reduce((a, b) => ({ ...a, ...b }), {});

        const display = [];

        for (let i = 1; i <= 8; i++) {
            display.push(batteries?.[i] || {});
        }

        return display;
    }, [device]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
                </Transition.Child>

                <div className="overflow-y-auto fixed inset-0 z-10">
                    <div className="flex justify-center items-stretch min-h-full text-center sm:items-center sm:px-6 lg:px-8">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-105"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-105"
                        >
                            <Dialog.Panel className="relative flex justify-center max-w-3xl text-base text-left transition transform sm:my-8 w-[436px]">
                                {device ? (
                                    <div className="flex overflow-hidden relative flex-col p-6 pt-6 pb-8 w-full bg-white sm:pb-6 lg:py-8">
                                        <div className="flex items-center">
                                            <div className="mr-4 text-lg font-semibold">
                                                {device?.serial_number}{' '}
                                            </div>
                                            <DeviceStatusPillComponent
                                                item={{ is_online: device.is_online }}
                                            />
                                            {device.is_faulty && (
                                                <DeviceStatusPillComponent
                                                    item={{ is_faulty: device.is_faulty }}
                                                    className="ml-2"
                                                />
                                            )}
                                            {device.is_frozen && (
                                                <DeviceStatusPillComponent
                                                    item={{ is_frozen: device.is_frozen }}
                                                    className="ml-2"
                                                />
                                            )}
                                        </div>
                                        <div className="flex justify-between items-center mt-5">
                                            <Toggle
                                                enabled={device?.is_frozen}
                                                setEnabled={updateDeviceFrozen}
                                                inverted
                                            />
                                            <BasicButton onClick={() => restartDevice(device.id)}>
                                                <ArrowPathIcon
                                                    height={20}
                                                    className="mr-2.5"
                                                    color="black"
                                                    fontWeight="bold"
                                                />
                                                Reiniciar
                                            </BasicButton>
                                            <BasicButton
                                                onClick={() => ejectAllBatteries(device.id)}
                                            >
                                                Ejetar todas
                                            </BasicButton>
                                        </div>
                                        <div className="mt-5 w-full">
                                            <BasicButton
                                                className="w-full"
                                                onClick={() =>
                                                    updateDeviceFaulty(!device.is_faulty)
                                                }
                                            >
                                                {device.is_faulty
                                                    ? 'Tirar de defeito'
                                                    : 'Colocar em defeito'}
                                            </BasicButton>
                                        </div>

                                        <div className="flex flex-col mt-4">
                                            {batteries.map((battery, index) => (
                                                <BatteryItem
                                                    battery={battery as any}
                                                    index={index + 1}
                                                    key={index}
                                                    ejectBattery={_ejectBattery}
                                                    toggleFaultyBattery={toggleFaultyBattery}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        <span className="sr-only">fechar</span>
                                        <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

interface BatteryItemProps {
    battery: Battery;
    index: number;
    ejectBattery: (index: number, battery: Battery) => void;
    toggleFaultyBattery: (index: number, battery: Battery) => void;
}

const BatteryItem: FC<BatteryItemProps> = ({
    battery,
    index,
    ejectBattery,
    toggleFaultyBattery,
}) => {
    const status = battery.is_faulty
        ? { label: 'Defeito', color: 'red' }
        : { label: 'Normal', color: 'green' };

    return (
        <div className="flex justify-between py-5 border-b border-gray-300">
            <div className="flex flex-col w-full">
                <div className="flex text-sm font-semibold text-gray-900">Slot {index}</div>
                <div className="flex mt-0.5 h-[20px] items-center justify-between">
                    {battery.serial_number && (
                        <>
                            <div className="text-gray-500">{battery.serial_number}</div>
                            <div className="flex gap-2 items-center">
                                <BadgePill>{battery.charge}%</BadgePill>
                                <BadgePill color={status.color as any}>{status.label}</BadgePill>
                            </div>
                        </>
                    )}
                </div>
                {battery?.serial_number && (
                    <Toggle
                        enabled={battery.is_faulty}
                        setEnabled={() => toggleFaultyBattery(index, battery)}
                        inverted
                    />
                )}
            </div>
            <div className="mt-1 ml-8">
                <BasicButton
                    onClick={() => ejectBattery(index, battery)}
                    disabled={!battery?.serial_number}
                >
                    Ejetar
                </BasicButton>
            </div>
        </div>
    );
};

export default DeviceBatteriesModal;
