import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AdminLayout, AdminLayoutContentCard } from '../../../common/components';
import { useBatteries } from '../../../common/hooks';
import { requiredFieldMessage } from '../../../common/utils/validation.utils';
import BatteryForm from './BatteryForm';
import { payloadFromSchema } from './CreateBattery';

const schema = yup
    .object({
        serial_number: yup.string().trim().required(requiredFieldMessage()),
        org_id: yup.object().shape({
            value: yup.string().trim(),
        }),
        is_faulty: yup.boolean(),
    })
    .required(requiredFieldMessage());

const buildFormFromData = (data: Battery): any => {
    return {
        serial_number: data.serial_number,
        org_id: { value: data.org?.id || '', label: data.org?.name || '' },
        is_faulty: data.is_faulty,
    };
};

const UpdateBattery = () => {
    const { updateBattery, loading, error } = useBatteries();
    const navigate = useNavigate();
    const location = useLocation();

    // 1: get battery data from navigation
    const batteryData = location.state?.data;

    // 2: fill form data with the battery data
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => batteryData && buildFormFromData(batteryData), [batteryData]),
    });

    useEffect(() => {
        reset(buildFormFromData(batteryData));
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        try {
            await updateBattery(batteryData.id, payload);
            navigate('/batteries');
        } catch (err) {
            // try again...
        }
    };

    return (
        <AdminLayout title="Alterar bateria">
            <AdminLayoutContentCard>
                <BatteryForm
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    errors={errors} // client-side validation
                    error={error} // server-side error
                    loading={loading}
                    isEdit={true}
                />
            </AdminLayoutContentCard>
        </AdminLayout>
    );
};

export default UpdateBattery;
