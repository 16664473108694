import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    fetchDataOptions?: boolean;
    orgId?: string;
}

const useDevices = (
    { fetchData = false, fetchDataOptions = false, orgId }: Options = {} as any,
) => {
    const [devices, setDevices] = useState<{ meta: any; data: any[] }>();
    const [deviceOptions, setDeviceOptions] = useState<any[]>([]);
    const { get, post, patch, _delete, loading, error } = useRequests();

    const fetchDevices = async ({ page, search }: FetchParams = {}) => {
        let route = '/admin/devices?';

        if (orgId) {
            route += `orgId=${orgId}&`;
        }

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setDevices(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchDeviceOptions = async () => {
        let route = `/admin/devices?perPage=1000&`;

        if (orgId) {
            route += `orgId=${orgId}`;
        }

        try {
            const response = await get(route);
            const data = response?.data?.data;
            const options = data?.map((device: any) => ({
                value: device.id,
                label: device.serial_number,
            }));
            setDeviceOptions(options || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createDevice = async (payload: any) => {
        let route = '/admin/devices';

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Máquina criada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateDevice = async (id: string, payload: any) => {
        let route = `/admin/devices/${id}`;

        try {
            const response = await patch(route, payload);
            setTimeout(() => toast.success('Máquina alterada com sucesso!'), 0);
            return response?.data;
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateBattery = async (id: string, payload: any) => {
        let route = `/admin/batteries/${id}`;

        try {
            const response = await patch(route, payload);
            setTimeout(() => toast.success('Bateria alterada com sucesso!'), 0);
            return response?.data;
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const ejectBattery = async ({
        device_id,
        ...payload
    }: {
        device_id: string;
        battery_serial_number: string;
        lock_index: number;
    }) => {
        if (!device_id) return;

        const route = `/admin/devices/${device_id}/eject-battery`;

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Solicitação enviada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const ejectAllBatteries = async (device_id: string) => {
        if (!device_id) return;

        const route = `/admin/devices/${device_id}/eject-all-batteries`;

        try {
            await post(route, {});
            setTimeout(() => toast.success('Solicitação enviada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const restartDevice = async (device_id: string) => {
        if (!device_id) return;

        const route = `/admin/devices/${device_id}/restart`;

        try {
            await post(route, {});
            setTimeout(() => toast.success('Solicitação enviada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteDevice = async (id: string) => {
        let route = `/admin/devices/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Máquina removida com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchDevices();
        }
        if (fetchDataOptions) {
            fetchDeviceOptions();
        }
        // eslint-disable-next-line
    }, []);

    return {
        devices,
        deviceOptions,
        fetchDevices,
        createDevice,
        updateDevice,
        updateBattery,
        deleteDevice,
        ejectBattery,
        ejectAllBatteries,
        restartDevice,
        error,
        loading,
    };
};

export default useDevices;
